// Auth exists due to circular dependencies using $http in both interceptors and authentication
// so we took out the $http utilization in authentication and put it here
/* @ngInject */
export default function authFactory($http, Config) {
  /**
   * @class
   * @memberOf module:app.shared
   */
  let apiUrl;
  let features;

  if (Config) {
    apiUrl = Config.apiUrl;
    features = Config.features;
  }

  class Auth {
    static login(username, password) {
      return $http({
        url: `${apiUrl}/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: undefined,
        },
        data: {
          username,
          password,
        },
        ...(features.twoFactorAuthentication && {params: {authenticate: true}, withCredentials: true}),
      });
    }

    static getNewJwt(authHeader) {
      return $http({
        url: `${apiUrl}/refreshJwt`,
        method: "POST",
        headers: {
          Authorization: authHeader,
        },
      });
    }
  }
  return Auth;
}
