import pugFile from "./layout/index.pug";

/* @ngInject */
export default function routing($stateProvider, $urlRouterProvider) {
  // Parent state for all logged in states - includes layout view with top bar and sidenav
  const app = {
    name: "App",
    abstract: true,
    template: pugFile(),
    controller: "AppController as app",
    resolve: {
      user: [
        "Authentication",
        "WorkflowsService",
        function getUser(Authentication, WorkflowsService) {
          let user;

          if (!Authentication.isAuthenticated()) {
            return Authentication.sendToLogin();
          }

          try {
            user = Authentication.getJwtPayload().user;
          } catch (error) {
            Authentication.removeJwt();

            if (error.name.includes("ExpirationError")) {
              return Authentication.sendToLogin(null, error.message);
            }
            return Authentication.sendToLogin();
          }

          return WorkflowsService.getWorkflowSettings().then(() => {
            return user;
          });
        },
      ],
    },
  };

  $urlRouterProvider.otherwise("/analyze");
  $stateProvider.state(app);
}
