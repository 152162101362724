/* eslint-disable max-classes-per-file */
import {DateTime} from "luxon";
import queryString from "qs";

/* @ngInject */
export default class ActionService {
  constructor($injector, backendConfig, $http, Authentication) {
    this._$injector = $injector;
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
    this._features = this._backendConfig.features;

    this.userFriendlyTypeNames = {
      requestErrorLogs: "Request Error Logs",
      requestEcgData: "Request ECG Data",
      sendMessage: "Send Message",
      endStudy: "End Study",
      updateSettings: "Update Settings",
      formatDevice: "Check In Device",
      forceCheckIn: "Force Check In Device",
      forceActionsFailure: "Force Actions Failure",
      replaceDevice: "Replace Device on Patient",
      convertMctToCem: "Downgrade to CEM Study",
      convertMctWithFullDisclosureToCem: "Downgrade to CEM Study",
      updateFirmware: "Update Firmware",
      resumeStudy: "Resume Study",
      startStudy: "Start Study",
      markStudyAsFailed: "Mark Study as Failed",
    };
  }

  /**
   * Makes an actions request for latest device settings
   * @param {String} enrollmentId
   * @param {Date} upperBoundTimestamp
   *
   * @returns {Promise<Object>} Promise that resolves to an object with the settings
   */
  getLatestSettings(enrollmentId, upperBoundTimestamp) {
    if (!enrollmentId) {
      return {};
    }

    const params = {};
    if (!["", null, undefined].includes(upperBoundTimestamp)) {
      params.upperBoundTimestamp = upperBoundTimestamp;
    }

    const url = `/actions/latestSettings/${enrollmentId}`;
    return this._httpGet(url, params)
      .then(({data: latestSettings}) => {
        if (latestSettings.studyHours) {
          latestSettings.studyDays = Math.round(latestSettings.studyHours / 24);
        }

        return latestSettings;
      })
      .catch((err) => {
        return {};
      });
  }

  getUserFriendlyActionData(action) {
    let formattedData = "";
    switch (action.name) {
      case "requestEcgData":
        try {
          const parsedData = JSON.parse(action.originalData);
          formattedData = `${DateTime.fromISO(parsedData.requestedTime).toFormat("yyyy-MM-dd HH:mm ZZZZ")}, ${
            parsedData.preDuration
          } min pre, ${parsedData.postDuration} min post, ${parsedData.comment}`;
        } catch (e) {
          /* Do Nothing */
        }
        break;
      case "requestErrorLogs":
      case "endStudy":
        if (action.comment) {
          formattedData = action.comment;
        } else {
          formattedData = action.originalData;
        }
        break;
      case "sendMessage":
        formattedData = action.comment;
        break;
      case "updateSettings":
      case "convertMctToCem":
      case "convertMctWithFullDisclosureToCem":
        if (action.comment) {
          formattedData = action.comment;
        } else {
          try {
            // Use study note instead of settings values
            formattedData = `${JSON.parse(action.originalData).studyNote}`;
          } catch (e) {
            /* Do Nothing */
          }
        }
        break;
      case "updateFirmware":
      case "formatDevice":
      case "replaceDevice":
      case "startStudy":
      case "forceActionsFailure":
        if (action.comment) {
          formattedData = action.comment;
        } else {
          formattedData = "";
        }
        break;
      default:
        formattedData = action.comment;
    }
    return formattedData;
  }

  _httpGet(url, params) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
