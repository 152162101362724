/* eslint-env browser */

/* @ngInject */

export default class AtrialFibrillationStatsService {
  /**
   * Compiles stats for the Atrial Fibrillation events from the arrhythmia timeline events object
   *
   * @param {Array} arrhythmiaEvents - Object containing all events during the Daily Trend or Summary
   * @param {Array} artifactRegions The artifact regions excluded in the arrhythmia timeline
   * @returns {Object} Groupings of AF stats
   *
   * @see SRS: BR-1169
   */
  getStats(arrhythmiaEvents, artifactRegions) {
    const eventTypesToExclude = ["Unreadable ECG Data", "Artifact", "Lead Off"];

    let totalDurationOfAllEvents = 0;
    let totalDurationOfAF = 0;
    let highestMedianHeartRate = null;
    let lowestMedianHeartRate = null;
    let longestAFEventDuration = 0;

    arrhythmiaEvents.forEach((event) => {
      let {startTime, endTime} = event;
      if (typeof endTime === "string") {
        endTime = new Date(endTime);
      }
      if (typeof startTime === "string") {
        startTime = new Date(startTime);
      }
      // removes all the impulse and unreadable events from our stats
      let eventDuration = endTime - startTime;
      if (eventDuration > 0 && !eventTypesToExclude.includes(event.eventName)) {
        totalDurationOfAllEvents += eventDuration;

        // Recalculate event duration to account for excluded regions
        artifactRegions.forEach((region) => {
          // Check whether the region overlaps with the event, i.e. the event starts in the period of the region or
          // ends in the period of the region
          const eventOverlapsRegion = startTime <= region.endTime && endTime >= region.startTime;

          if (eventOverlapsRegion && eventDuration > 0) {
            // Because overlap duration between event and artifact region can never be greater than event duration,
            // calculate the overlap and use the value to update the event and artifact durations
            const maxStart = Math.max(region.startTime, startTime);
            const minEnd = Math.min(region.endTime, endTime);
            eventDuration -= minEnd - maxStart;
          }
        });

        // Compile AF stats
        if (event.eventName === "Atrial Fibrillation" || event.eventName === "AF") {
          totalDurationOfAF += eventDuration;
          // Highest Median Heart Rate
          if (
            typeof event.highestMedianHeartRate === "number" &&
            (highestMedianHeartRate < event.highestMedianHeartRate || highestMedianHeartRate === null)
          ) {
            highestMedianHeartRate = event.highestMedianHeartRate;
          }
          // Lowest Median Heart Rate
          if (
            typeof event.lowestMedianHeartRate === "number" &&
            (lowestMedianHeartRate > event.lowestMedianHeartRate || lowestMedianHeartRate === null)
          ) {
            lowestMedianHeartRate = event.lowestMedianHeartRate;
          }
          // Longest AF event
          if (eventDuration > longestAFEventDuration) {
            longestAFEventDuration = eventDuration;
          }
        }
      }
    });

    const durations = [
      {category: "In AF", duration: totalDurationOfAF},
      {category: "Not in AF", duration: totalDurationOfAllEvents - totalDurationOfAF},
    ];

    durations.forEach((grouping) => {
      grouping.percent = Math.round((grouping.duration / totalDurationOfAllEvents) * 100);
      grouping.duration = _convertMillisecondsToHMS(grouping.duration);
    });

    if (highestMedianHeartRate === null) {
      highestMedianHeartRate = "N/A";
    }
    if (lowestMedianHeartRate === null) {
      lowestMedianHeartRate = "N/A";
    }

    const atrialFibrillationStats = {
      totalDurationOfAllEvents: _convertMillisecondsToHMS(totalDurationOfAllEvents),
      totalDurationOfAF: _convertMillisecondsToHMS(totalDurationOfAF),
      highestMedianHeartRate,
      lowestMedianHeartRate,
      longestAFEventDuration: _convertMillisecondsToHMS(longestAFEventDuration),
      durations,
    };
    return atrialFibrillationStats;
  }

  formatAdditionalData(atrialFibrillationStats) {
    return [
      {
        description: "Highest Median HR: ",
        value: `${atrialFibrillationStats.highestMedianHeartRate}`,
        idPrefix: "highestMedianHeartRate-",
      },
      {
        description: "Lowest Median HR: ",
        value: `${atrialFibrillationStats.lowestMedianHeartRate}`,
        idPrefix: "lowestMedianHeartRate-",
      },
      {
        description: "Longest Episode: ",
        value: `${atrialFibrillationStats.longestAFEventDuration}`,
        idPrefix: "longestAfEventDuration-",
      },
    ];
  }
}

/**
 * Converts milliseconds to a displayable hh:mm:ss format
 * @param {number} milliseconds
 * @returns {string}
 * @memberOf module:app.shared/atrialFibrillationStatsService
 * @see SRS: BR-1169
 * @private
 */
function _convertMillisecondsToHMS(milliseconds) {
  // Convert to seconds
  let seconds = milliseconds / 1000;
  // Extract days
  const days = Math.floor(seconds / 86400); // 86,400 seconds in 1 day
  seconds %= 86400; // seconds remaining after extracting days
  // Extract hours
  const hours = Math.floor(seconds / 3600); // 3,600 seconds in 1 hour
  seconds %= 3600; // seconds remaining after extracting hours
  // Extract minutes
  const minutes = Math.floor(seconds / 60); // 60 seconds in 1 minute
  // Keep only seconds not extracted to minutes
  seconds = Math.round(seconds % 60);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}
