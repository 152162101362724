/* eslint-env browser */
import * as d3 from "d3";

/* @ngInject */
export default class DonutChartService {
  constructor() {
    this.init();
  }

  init() {
    // Save the SVGs by containerId; updating the chart will not create a new svg
    this.donutChartSVGs = {};
  }

  /**
   * Adds the donut chart for the given data and container to the card
   *
   * @param {string} containerId The parent container id for the donut chart
   * @param {Array} donutData All of the data for the donut chart
   * @param {Boolean} greyscale
   * @param {Boolean} animationTime
   *
   * @memberOf module:app.shared/donutChartService
   * @see SRS: BR-1172, BR-1173, BR-1168
   */
  showDonutChart(containerId, donutData, greyscale = false, animationTime = 1000) {
    const parentWidth = document.getElementById(containerId).offsetWidth;
    const pie = d3
      .pie()
      .value(function setValue(data) {
        return data.percent;
      })
      .sort(null)
      .padAngle(0);

    const w = parentWidth;
    const h = w;
    const outerRadius = w / 2;
    const innerRadius = 25;
    const donutSections = donutData.length;

    const arc = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius);

    let svg;
    const previousContainer = document.getElementById(containerId);
    if (containerId in this.donutChartSVGs && previousContainer?.children.length > 0) {
      // Reference the svg from static data
      svg = this.donutChartSVGs[containerId];
    } else {
      // Create the svg for the donut chart
      svg = d3
        .select(`#${containerId}`)
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin")
        .attr("viewBox", `-${w / 2} -${h / 2} ${w} ${h}`);
      svg.append("g").attr("transform", `translate(${w / 2},${h / 2})`);
      this.donutChartSVGs[containerId] = svg;
    }

    // Delete the existing donut chart if there is one
    svg.selectAll("path").remove();
    // Create the paths for the filled in pieces of the donut chart
    const path = svg
      .selectAll("path")
      .data(pie(donutData))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d, i) => this.getIconColor(d, i, donutSections, greyscale));

    path
      .transition()
      .duration(animationTime)
      .attrTween("d", function interpolateData(d) {
        const interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
        return function makeTransition(t) {
          return arc(interpolate(t));
        };
      });
  }

  /**
   * Gets the corresponding icon color for the donut chart legend item.
   *
   * @param {Object} stat The object containing the stat information
   * @param {Number} index The index of the legend item
   * @param {Number} total The total number of legend items
   * @param {Boolean} greyscale The total number of legend items
   * @returns {String} The corresponding color's hex value
   *
   * @memberOf module:app.shared/donutChartService
   * @see SRS: BR-1173
   */
  getIconColor(stat, index, total, greyscale) {
    // Grey out unclassifed event stats
    if (!greyscale && (stat?.data?.name === "Unclassified" || stat?.name === "Unclassified")) {
      return "#62727b";
    }

    let range = ["#abe2fc", "#004c8b"];
    if (greyscale) {
      range = ["#dbdbdb", "#7a7a7a"];
    }

    const myColor = d3
      .scaleLinear()
      .domain([0, total - 1])
      .range(range);
    return myColor(index);
  }
}
